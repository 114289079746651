function loadInstagramWidget() {
    let accessToken = "";
    const gallery = document.querySelector('.instagram-widget-gallery');
    var ajaxurl = '/wp-admin/admin-ajax.php';

    const accessTokenPromise = new Promise((resolve, reject) => {
        var xhr = new XMLHttpRequest();

        xhr.onreadystatechange = function() {
            if (xhr.readyState === 4) {
                if (xhr.status === 200) {
                    // Resolve the promise with the access token
                    resolve(xhr.responseText);
                } else {
                    // Reject the promise with the error message
                    reject(new Error(`Failed to get access token: ${xhr.statusText}`));
                }
            }
        };

        xhr.open('POST', ajaxurl);
        xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
        xhr.send('action=get_token');
    });

    accessTokenPromise.then((response) => {
        // Set the accessToken variable
        accessToken = response;

        const fields = 'media_url, media_type'
        const maxNum = 4;

        fetch(`https://graph.instagram.com/me/media?fields=${fields}&limit=${maxNum}&access_token=${accessToken}`)
            .then(response => response.json())
            .then(data => {
                data.data.forEach(item => {
                    const mediaContainer = document.createElement('a');
                    mediaContainer.className = 'media-container instagram-widget-gallery-item';
                    mediaContainer.setAttribute('target', '_blank');
                    mediaContainer.href = 'https://instagram.com/froschgift';

                    let media;

                    switch(item.media_type) {
                        case "CAROUSEL_ALBUM":
                        case "IMAGE": 
                            media = new Image();
                            media.src = item.media_url;
                            break;
                        case "VIDEO": 
                            media = document.createElement("video");
							media.setAttribute("playsinline", "");
                            media.setAttribute("src", item.media_url);

                            media.muted = true;
                            media.autoplay = true;
                            break;

                        default:
                            break;
                    }

                    if(!media.src) {
                        return;
                    }
                    mediaContainer.replaceChildren(media)
                    gallery.append(mediaContainer)
                });
            })
            .catch(error => {
                console.error(error)
            });
    }).catch((error) => {
        console.error(error);
    });
}


document.querySelector('.button--menu').addEventListener("click", function createWidget() {
	loadInstagramWidget();
	document.querySelector('.button--menu').removeEventListener("click", createWidget)
})