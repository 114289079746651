import {
	createElement,
	createVideo,
	createHeader,
	createTextblock,
	createMail,
	createButton,
	createParagraph,
	createGraphics,
	createHeaderDuplicate,
	addSourceSet,
	addBrowserBorder,
	createList,
} from './_createElements.js';
import { SEO, siteUrl, getPageData } from './_page.js';
import {
	TaxonomyCluster,
	ImageTextBlock,
	NewsArticle,
	Services,
	ImageTextServices,
	renderHeroBlock,
} from './_blocks.js';
import { Button } from './_components.js';
import { sliderObserver, teamObserver } from './_scrollEvents.js';

export async function renderData(page) {
	let promises = [];
	let contentArray = [];

	let pageFragment = document.createDocumentFragment();

	if (page.type == 'page' || page.type == 'leistungen') {
		// display all modules
		var pagelinkElements = [];
		for (const [i, element] of page.acf.modul.entries()) {
			if (!element.acf_fc_layout) {
				console.warn('Element does not contain ACF layout.');
				return;
			}

			let page_module = document.createElement('div');
			let block;

			switch (element.acf_fc_layout) {
				case 'intro':
					element.order = i;
					const heroBlock = renderHeroBlock(element, page);
					promises.push(heroBlock);

					break;

				case 'textblock':
					var container = document.createElement('div');
					container.classList.add(element.acf_fc_layout);
					container.setAttribute('data-animation', 'viewport');

					var textblock = createTextblock(
						element.ueberschrift,
						'h1',
						element.liste,
						element.text
					);
					textblock.querySelector('h1').classList.add('multi-line');
					container.append(textblock);

					contentArray[i] = container;

					break;

				case 'heading':
					element.order = i;
					var container = createElement({
						class: element.acf_fc_layout,
					});
					container.setAttribute('data-animation', 'viewport');

					var title = createHeader(
						'h2',
						element.ueberschrift + element.subline.text
					);

					title.classList.add('multi-line');

					let { farbe, form, transparenz } = element.subline.farbfeld;

					title.querySelectorAll('span.mask > span').forEach((el) => {
						if (!el.innerText.includes(element.subline.text)) {
							return;
						}
						el.classList.add('target');
						el.style.setProperty('--farbe', farbe);
						el.style.setProperty('--form', form);
						el.style.setProperty('--transparenz', transparenz);
						el.innerHTML +=
							"<div class='riso-mask'><div class='riso'></div></div>";
					});

					container.append(title);
					contentArray[i] = container;

					break;

				case 'taxonomien':
					element.order = i;

					let props;

					if (element.sorting_option == 'auto') {
						props = {
							type: element.taxonomie,
							category: element.auto.category,
							quantity: element.auto.maximalanzahl,
						};
					} else if (element.sorting_option == 'manual') {
						let IDs = [];
						let entries = element.manual[element.taxonomie];

						entries.forEach((el) => {
							IDs.push(el.ID);
						});

						props = {
							id: IDs.join(','),
							type: element.taxonomie,
						};
					}

					await queryData(props).then((response) => {
						element.data = response;
						element.page = page.type;
						let content = displayContent(
							element,
							element.taxonomie,
							true
						);
						promises.push(content);
					});

					break;

				case 'pagelink':
					element.order = i;
					pagelinkElements.push(element);

					break;

				case 'sticky_taxonomien':
					element.order = i;
					var taxonomyPromise = displaySticky(element);
					promises.push(taxonomyPromise);

					break;

				case 'sticky_text':
					element.order = i;
					var stickyText = displaySticky(
						element,
						element.acf_fc_layout
					);
					stickyText.then((response) => {
						contentArray[i] = response[1];
					});
					break;

				case 'image_text_services':
					block = new ImageTextServices(element).render();
					contentArray[i] = block;

					break;

				case 'news_article':
					const newsArticleBlock = displayNewsArticle(element);
					contentArray[i] = newsArticleBlock;

					break;

				case 'cta_kontakt':
					var cta = displayCTA(element, i);

					promises.push(cta);
					break;

				case 'taxonomy_cluster':
					page_module.classList.add('taxonomy-cluster');

					block = new TaxonomyCluster(element);
					page_module.append(block.render());
					contentArray[i] = page_module;

					break;

				case 'image_text_block':
					page_module.classList.add('image-text-block');

					block = new ImageTextBlock(element);
					page_module.append(block.render());

					contentArray[i] = page_module;

					break;
			}
		}
		// handle IDs of pagelink in a single query
		if (pagelinkElements.length > 0) {
			const promise = new Promise(async (resolve) => {
				const pageLinks = await renderPageLinks(pagelinkElements);
				resolve(pageLinks);
			});

			promises.push(promise);
		}
	} else if (page.type == 'projekte') {
		page.order = 0;
		const isArchive = page.categories.includes(3);
		const content = displayContent(page, page.type, false);
		promises.push(content);
	} else if (page.type == 'team') {
		let section = createElement({
			node: 'section',
			id: page.type,
		});

		await queryData({
			type: page.type,
			quantity: 99,
		}).then(async (content) => {
			content.order = 0;
			content = await displayTeamMembers(content, false, page.id);
			promises.push(content);
		});
	} else if (page.type == 'jobs') {
		const content = displayJobs(page, false);
		promises.push(content);
	}

	// Process all promises
	await Promise.all(promises).then((results) => {
		for (const result of results) {
			if (Array.isArray(result[0])) {
				for (const deepResult of result) {
					contentArray[deepResult[0]] = deepResult[1];
				}
			} else {
				const [index, value] = result;
				contentArray[index] = value;
			}
		}
	});

	contentArray.forEach((el) => {
		pageFragment.appendChild(el);
	});

	return pageFragment;
}

export function queryData(params) {
	let queryString = '';
	let queryFilter = [];

	params.type = params.type === 'page' ? 'pages' : params.type;

	let query = {
		id: params.id ? 'include=' + params.id : '',
		slug: params.slug ? 'slug=' + params.slug : '',
		category: params.category ? 'categories=' + params.category : '',
		quantity: params.quantity ? 'per_page=' + params.quantity : '',
		fields: params.fields ? '_fields=' + params.fields.join(',') : '',
		status: 'status=publish',
	};

	for (const key in query) {
		if (Object.hasOwnProperty.call(query, key)) {
			const element = query[key];
			if (element.length === 0) continue;
			queryFilter.push(element);
		}
	}

	queryString += queryFilter.join('&');
	return fetch(
		window.location.origin +
			'/wp-json/wp/v2/' +
			params.type +
			'?' +
			queryString
	).then((response) => response.json());
}

function displayContent(content, type, isPreview) {
	switch (type) {
		case 'projekte':
			return displayProjects(content, isPreview);

		case 'team':
			return displayTeamMembers(content, isPreview, -1);

		case 'jobs':
			return displayJobs(content, isPreview);
	}
}

const displayCTA = async (content, index) => {
	let wrapper = createElement({ node: 'section', id: 'cta-kontakt' });
	wrapper.setAttribute('data-animation', 'viewport');

	let title = createHeader('h2', content.ueberschrift, 'ueberschrift');

	let text = createElement({ class: 'text' });
	let paragraph = createParagraph(content.text);

	let illustration = createElement({ node: 'img', class: 'illustration' });
	illustration.dataset.animation = 'viewport';

	var taxonomyPromise = new Promise((resolve) => {
		queryData({
			type: content.kontaktperson[0].post_type,
			id: content.kontaktperson[0].ID,
		}).then((content) => {
			let mail = createMail(content[0].acf.info.mail);
			paragraph.append(mail);

			illustration.src = content[0].acf.bilder.illustration.url;
			illustration.alt = content[0].acf.bilder.illustration.title;
			text.append(paragraph, illustration);
			wrapper.append(title, text);
			resolve([index, wrapper]);
		});
	});

	return taxonomyPromise;
};

const displaySticky = (element) => {
	const promise = new Promise(async (resolve) => {
		const wrapper = createElement({
			node: 'section',
			class: 'sticky-scroll',
		});
		const imgWrapper = createElement({ class: 'sticky-image' });
		const stickyImg = createElement({
			node: 'img',
			src: element.illustration.url,
			class: 'illustration',
		});
		stickyImg.setAttribute('data-animation', 'viewport');
		stickyImg.alt = element.illustration.alt;
		imgWrapper.append(stickyImg);

		const container = document.createElement('div');
		container.className = 'entries';

		if (element.acf_fc_layout === 'sticky_taxonomien') {
			container.classList.add(element.taxonomie);

			const content = await queryData({
				type: element.taxonomie,
				quantity: element.maximalanzahl,
			});

			const response = await displayContent(
				content,
				element.taxonomie,
				true
			);
			container.append(response[1]);
		} else if (element.acf_fc_layout === 'sticky_text') {
			if (!element.textblock) {
				return;
			}

			for (const entry of element.textblock) {
				const textblock = createElement({ class: 'textblock' });
				textblock.setAttribute('data-animation', 'viewport');
				const innerBlock = createTextblock(
					entry.ueberschrift,
					'h2',
					entry.liste,
					entry.text
				);
				textblock.append(innerBlock);
				container.append(textblock);
			}
		}

		wrapper.append(imgWrapper, container);
		resolve([element.order, wrapper]);
	});

	return promise;
};

const displayNewsArticle = (element) => {
	let wrapper = createElement({ node: 'section', class: 'news-article' });
	let imgWrapper = createElement({ class: 'sticky-image' });
	let stickyImg = createElement({
		node: 'img',
		src: element.image.url,
		class: 'illustration',
	});
	stickyImg.setAttribute('data-animation', 'viewport');
	stickyImg.alt = element.image.alt;
	imgWrapper.append(stickyImg);

	var container = document.createElement('div');

	container.className = 'entries';

	for (const entry of element.textblock) {
		let textblock = createElement({ class: 'textblock' });
		textblock.setAttribute('data-animation', 'viewport');
		var innerBlock = createTextblock(
			entry.ueberschrift,
			'h2',
			entry.liste,
			entry.text
		);
		textblock.append(innerBlock);

		if (entry.button.label && entry.button.link) {
			const buttonInfo = {
				wrap: false,
				link: entry.button.link,
				text: entry.button.label,
			};

			const button = createButton(buttonInfo);

			textblock.querySelector('.text').append(button);
		}

		container.append(textblock);
	}

	wrapper.append(imgWrapper, container);
	return wrapper.outerHTML;
};

const displayTeamMembers = (members, isPreview, firstMemberId) => {
	let content = document.createDocumentFragment();

	let jumpLabelString = '';
	let membersString = '';

	let jumpLabels = createElement({ class: 'jumplabels' });
	jumpLabels.setAttribute('data-animation', 'viewport');
	let wrapper = createElement({ class: 'entries' });

	// Sort member to first place in the list according to id
	if (firstMemberId >= 0) {
		members.sort(function (x, y) {
			return x.id == firstMemberId ? -1 : y.id == firstMemberId ? 1 : 0;
		});
	}

	let section = createElement({
		node: 'section',
		id: 'team',
	});

	let loop;
	if (members.data) {
		loop = members.data;
	} else {
		loop = members;
	}

	loop.forEach((member, index) => {
		if (isPreview) {
			section.setAttribute('data-preview', true);
			let entry = createElement({ id: member.slug, class: 'member' });
			entry.setAttribute('data-animation', 'viewport');

			var thumbnail = createElement({ class: 'thumbnail' });
			thumbnail.setAttribute('data-transition-type', 'image');
			thumbnail.setAttribute('data-transition-keep', '');

			var link = createElement({
				node: 'a',
				href: member.link,
				class: 'image-container',
			});
			link.setAttribute('data-transition', 'team');

			var image = document.createElement('img');
			addSourceSet(image, member.acf.bilder.portrait, 30, 50);
			image.classList.add('image-content');

			link.append(image);

			thumbnail.append(link);
			let name = createHeader('h3', member.acf.info.vorname, 'name');
			let role = createElement({ node: 'p', class: 'role text-mask' });
			role.innerHTML = '<span>' + member.acf.info.job + '</span>';

			entry.append(thumbnail, name, role);
			wrapper.append(entry);
			section.append(wrapper);
			content.append(section);
		} else {
			section.setAttribute('data-preview', 'false');
			section.setAttribute('data-scroll', 'horizontal');

			// Jumplabel
			const jumpLabel = document.createElement('a');
			jumpLabel.className = 'jumpMarkerTeam';
			jumpLabel.href = '/team/' + member.slug + '/';
			jumpLabel.setAttribute('data-member', member.slug);

			const { farbe, form, transparenz } = member.acf.bilder.farbfeld;
			jumpLabel.style.setProperty('--riso-color', farbe);
			jumpLabel.style.setProperty('--riso-form', form);
			jumpLabel.style.setProperty(
				'--riso-transparenz',
				transparenz / 100
			);

			jumpLabel.addEventListener('click', jumpToMember);

			if (index == 0) {
				jumpLabel.classList.add('active');
			}

			let jumpLabelImage = createElement({
				node: 'img',
				class: 'jumpLabelImage',
				src: member.acf.bilder.illustration.url,
			});
			jumpLabel.append(jumpLabelImage);
			jumpLabels.append(jumpLabel);

			// Teammember
			const entry = createElement({ id: member.slug, class: 'member' });
			entry.setAttribute('data-animation', 'viewport');

			sliderObserver.observe(entry);
			teamObserver.observe(entry);

			if (index == 0) {
				entry.classList.add('active');
			}

			entry.style.setProperty(
				'--farbe',
				member.acf.bilder.farbfeld.farbe
			);
			entry.style.setProperty(
				'--riso-form',
				member.acf.bilder.farbfeld.form
			);
			entry.style.setProperty(
				'--riso-transparenz',
				member.acf.bilder.farbfeld.transparenz / 100
			);

			var thumbnail = createElement({ class: 'image-container' });
			thumbnail.setAttribute('data-transition', 'target');

			var image = document.createElement('img');
			image.classList.add('image-content');
			addSourceSet(image, member.acf.bilder.portrait, 30, 80);

			image.addEventListener('click', (e) => {
				entry.classList.toggle('show-content');
			});

			thumbnail.append(image);

			let names =
				'<span class="vorname">' +
				member.acf.info.vorname +
				'</span><span> ' +
				member.acf.info.nachname +
				'</span>';
			let nameString = 'Das ist <br />' + names;
			let name = createHeader('h2', nameString, 'title');

			name.classList.add('multi-line');

			let risoMask = createElement({ class: 'riso-mask' });
			let risoEl = createElement({ class: 'riso' });
			risoMask.append(risoEl);

			name.querySelector('.vorname').append(risoMask);

			var role = createElement({ node: 'h4', class: 'role text-mask' });
			role.innerHTML = '<span>' + member.acf.info.job + '</span>';

			var textPrivate = document.createElement('p');
			textPrivate.innerText = member.acf.text.privat;

			var textJob = document.createElement('p');
			textJob.innerText = member.acf.text.beruf;

			let hiddenText = createElement({ node: 'p', class: 'hidden-text' });
			hiddenText.innerText = member.acf.text.versteckt;

			let textfield = createElement({ class: 'textfield' });
			textfield.append(role, textPrivate, textJob, hiddenText);

			var gallery = createElement({ class: 'gallery' });

			for (const bild of member.acf.galerie) {
				var container = document.createElement('div');
				var image = document.createElement('img');
				addSourceSet(image, bild, 20, 30);
				container.append(image);
				gallery.append(container);
			}

			entry.append(name, thumbnail, textfield, gallery);
			wrapper.append(entry);
			section.append(jumpLabels, wrapper);
			content.append(section);
		}
	});

	const promise = new Promise((resolve) => {
		resolve([members.order, content]);
	});

	return promise;
};

const displayProjects = (projects, isPreview) => {
	let fragment = document.createDocumentFragment();

	if (isPreview) {
		let section = createElement({
			node: 'section',
			id: projects.taxonomie,
		});

		section.setAttribute('data-preview', true);

		projects.data.forEach((project, index) => {
			if (!project.acf) {
				return;
			}

			let container = createElement({ class: 'project' });
			container.setAttribute('data-transition-keep', '');
			container.setAttribute('data-animation', 'viewport');
			if (index == 0) container.classList.add('active');

			sliderObserver.observe(container);

			/* Thumbnail + Risoshapes */
			let thumbnail = createElement({ class: 'thumbnail' });
			let imageContainer = createElement({
				node: 'a',
				class: 'image-container',
				href: project.link,
			});

			// let transitionType;
			// if (projects.page == 'leistungen' || projects.page == 'projekte') {
			// 	transitionType = 'default';
			// } else {
			// 	transitionType = 'project';
			// }

			// imageContainer.setAttribute('data-transition', transitionType);
			imageContainer.setAttribute('data-transition', 'project');
			imageContainer.setAttribute('data-transition-clone', '');

			let image = document.createElement('img');
			image.src = project.acf.grafiken.bild.url;
			image.alt = project.title.rendered;
			addSourceSet(
				image,
				project.acf.grafiken.bild,
				100,
				50,
				false,
				true
			);
			imageContainer.append(image);

			let farbflaechen = project.acf.grafiken.farbflaechen;
			let risoContainer = createElement({ class: 'farbflaechen' });
			for (let flaeche in farbflaechen) {
				let array = farbflaechen[flaeche];
				let form = array.form;
				let farbe = array.farbe;
				let transparenz = array.transparenz / 100;

				let shapes = document.createElement('div');

				let el = document.createElement('div');
				el.className = 'riso-mask';
				el.style.setProperty('--farbe', farbe);
				el.style.setProperty('--form', form);
				el.style.setProperty('--transparenz', transparenz);

				let risoEl = document.createElement('div');
				risoEl.className = 'riso';

				el.append(risoEl);

				risoContainer.append(el);
			}

			thumbnail.append(imageContainer, risoContainer);

			var button = {
				link: project.link,
				text: 'Projekt ansehen',
				transition: 'project',
			};
			var innerBlock = createTextblock(
				project.title.rendered,
				'h2',
				project.acf.kurzinfo.leistungen,
				project.acf.kurzinfo.abstract,
				button
			);

			innerBlock.querySelector('h2').classList.add('multi-line');

			var textblock = createElement({ class: 'textblock' });
			textblock.append(innerBlock);

			container.append(thumbnail, textblock);
			section.appendChild(container);
		});

		var button = {
			link: siteUrl + '/projekte/',
			text: 'Alle Projekte',
			transition: 'default',
			wrap: true,
		};
		var linkButton = createButton(button);
		section.appendChild(linkButton);
		fragment.appendChild(section);
	} else {
		const project = projects;
		if (!project.acf) {
			return;
		}
		const isArchive =
			project.categories.includes(3) || project.categories.includes(5);

		let section = createElement({
			node: 'section',
			id: projects.type,
		});

		section.setAttribute('data-category', project.categories);
		section.setAttribute('data-preview', 'false');

		let clientColor = project.acf.kundenfarbe;
		let logoClone = createHeaderDuplicate();

		// Project Container
		let projectContainer = createElement({ class: 'project' });
		projectContainer.style.setProperty('--client-color', clientColor);
		projectContainer.dataset.id = project.id;

		/* Project Header */
		let entryHeader = createElement({ class: 'entry-header' });
		entryHeader.setAttribute('data-animation', 'viewport');

		let background = createElement({ class: 'background-image' });
		background.setAttribute('data-transition', 'target');
		background.style.setProperty(
			'--bg-image',
			"url('" + project.acf.grafiken.bild.url + "')"
		);

		let textfield = createElement({ class: 'textblock' });
		let textblock = createTextblock(
			project.title.rendered,
			'h1',
			project.acf.kurzinfo.leistungen,
			project.acf.kurzinfo.abstract
		);

		textblock.querySelector('h1').classList.add('multi-line');

		textfield.append(textblock);

		entryHeader.append(logoClone, textfield /* background */);

		/* Brand Introduction */
		let intro = createElement({ class: 'brand-introduction' });
		intro.setAttribute('data-animation', 'viewport');

		let description = createElement({ class: 'description' });
		description.append(
			createParagraph(project.acf.einleitung.kundenbeschreibung)
		);

		let ueberschrift = createHeader(
			'h2',
			project.acf.einleitung.ueberschrift
		);

		let logo = createElement({
			node: 'img',
			class: 'client-logo',
			src: project.acf.einleitung.logo.url,
		});
		logo.alt = project.acf.einleitung.logo.alt;

		let introductions = document.createDocumentFragment();
		for (const introText of project.acf.einleitung.einleitungstexte) {
			let block = createElement({ class: 'intro-block' });

			let title = createHeader('h3', introText.ueberschrift);
			let text = createParagraph(introText.text);

			block.append(title, text);
			introductions.append(block);
		}

		intro.append(
			logoClone.cloneNode(true),
			description,
			logo,
			ueberschrift,
			introductions
		);

		/* Brand Strategy */
		let strategy = createElement({ class: 'brand-strategy' });
		strategy.setAttribute('data-animation', 'viewport');

		let strategyTitle = createHeader(
			'h2',
			project.acf.brand_strategy.ueberschrift
		);

		let strategies = document.createDocumentFragment();
		for (let content of project.acf.brand_strategy.content) {
			let entry = createElement({ class: 'entry' });

			let container = createElement({ class: 'strategy-block' });
			container.setAttribute('data-animation', 'viewport');

			let textfield = document.createElement('div');
			textfield.className = 'textfield';

			content.strategietexte.forEach((v, i) => {
				let textEntry = document.createElement('div');
				let heading = createHeader('h3', v.ueberschrift);

				let paragraph = document.createElement('template');
				paragraph.innerHTML = v.text;

				let quote = document.createElement('blockquote');
				quote.innerText = v.zitat;

				textEntry.append(heading, paragraph.content, quote);
				textfield.append(textEntry);
			});

			container.append(textfield);

			let imageContainer = createElement({
				class: 'imgContainer ' + content.bilder.image_type,
			});
			imageContainer.setAttribute('data-animation', 'viewport');

			switch (content.bilder.image_type) {
				case 'sticky':
					var image = document.createElement('img');
					addSourceSet(
						image,
						content.bilder.sticky_image,
						50,
						100,
						false,
						true
					);

					imageContainer.append(image);
					break;
				case 'collage':
					var image = document.createElement('img');
					addSourceSet(
						image,
						content.bilder.collage,
						50,
						100,
						false,
						true
					);

					imageContainer.append(image);
					break;
				case 'video':
					let video = createVideo(content.bilder);
					imageContainer.append(video);
			}

			container.append(imageContainer);
			strategies.append(container);
		}

		strategy.append(logoClone.cloneNode(true), strategyTitle, strategies);

		/* Brand Experience */
		let experience = createElement({ class: 'brand-experience' });
		experience.setAttribute('data-animation', 'viewport');

		let experienceTitle = createHeader(
			'h2',
			project.acf.brand_experience.ueberschrift
		);

		let experiences = document.createDocumentFragment();
		for (let experience of project.acf.brand_experience.experience_blocks) {
			let block = createElement({ class: 'experience-block' });
			block.setAttribute('data-animation', 'viewport');

			let textfield = createElement({ class: 'textfield' });

			let title = createHeader('h3', experience.ueberschrift);
			let text = createParagraph(experience.text);

			textfield.append(title, text);

			let pictures = createElement({ class: 'pictures' });

			for (let bild of experience.bilder) {
				let container;
				switch (bild.acf_fc_layout) {
					case 'bild':
						container = createElement({
							class: 'imgContainer media-container--no-border-radius',
						});
						container.setAttribute('data-animation', 'viewport');
						var image = document.createElement('img');
						addSourceSet(image, bild.image, 50, 100, false, true);
						if (bild.browser_rahmen) {
							addBrowserBorder(container);
						}
						container.append(image);
						pictures.append(container);
						break;
					case 'video':
						container = createElement({
							node: 'div',
							class: 'imgContainer media-container--no-border-radius',
						});
						container.setAttribute('data-animation', 'viewport');
						container.setAttribute('loading', 'lazy');
						let video = createVideo(bild);
						if (bild.browser_rahmen) {
							addBrowserBorder(container);
						}
						container.append(video);
						pictures.append(container);
						break;
					case 'masonry':
						let masonry = createElement({
							node: 'div',
							class: 'masonry',
						});
						masonry.setAttribute('data-animation', 'viewport');
						bild.bilder.forEach((v, i) => {
							let row = document.createElement('div');
							row.className = 'row';
							v.reihe.forEach((v, i) => {
								let image = document.createElement('img');
								addSourceSet(image, v, 25, 100, false, true);
								row.append(image);
							});
							masonry.append(row);
						});

						pictures.append(masonry);
						break;
					case 'mobile_gallery':
						if (!bild.screens) {
							break;
						}
						container = createElement({
							node: 'div',
							class: 'screens imgContainer',
						});
						container.setAttribute('data-animation', 'viewport');
						bild.screens.forEach((v, i) => {
							let mediaContainer = createElement({
								node: 'div',
								class: 'media-container',
							});
							addBrowserBorder(mediaContainer);

							if (v.type == 'video') {
								let video = createVideo(v);
								mediaContainer.append(video);
								container.append(mediaContainer);
							} else if (v.type == 'image') {
								let image = document.createElement('img');
								addSourceSet(image, v, 50, 100, false, true);
								mediaContainer.append(image);
								container.append(mediaContainer);
							}
						});
						pictures.append(container);
						break;
				}
			}

			let stickyScroll = createElement({
				node: 'div',
				class: 'sticky-scroll',
			});

			stickyScroll.append(textfield, pictures);

			let fullImage = createElement({ node: 'div', class: 'full-image' });
			fullImage.setAttribute('data-animation', 'viewport');

			let footerPicture;
			if (experience.footer_bild !== false) {
				footerPicture = document.createElement('img');
				addSourceSet(
					footerPicture,
					experience.footer_bild,
					100,
					100,
					false,
					true
				);
				fullImage.append(footerPicture);
			} else if (experience.footer_video !== false) {
				footerPicture = document.createElement('video');
				footerPicture.autoplay = true;
				footerPicture.setAttribute('muted', '');
				footerPicture.loop = true;

				footerPicture.src = experience.footer_video.url;
			}

			fullImage.append(footerPicture);
			block.append(stickyScroll, fullImage);
			experiences.append(block);
		}

		experience.append(
			logoClone.cloneNode(true),
			experienceTitle,
			experiences
		);

		/* Credits */
		let credits = createElement({ node: 'div', class: 'credits' });
		credits.setAttribute('data-animation', 'viewport');

		let container = createElement({ node: 'div', class: 'container' });
		if (project.categories.includes(3) || project.categories.includes(5)) {
			container.style.paddingBottom = 0;
		}

		let creditsTitle = createHeader('h2', 'Credits');
		let creditsText = createList(project.acf.credits);
		container.append(creditsTitle, creditsText);
		credits.append(logoClone.cloneNode(true), container);

		projectContainer.append(
			entryHeader,
			intro,
			strategy,
			experience,
			credits,
			background
		);
		section.appendChild(projectContainer);

		if (!isArchive) {
			// return;
			const modalClone = document
				.querySelector('header #modal')
				.cloneNode(true);
			modalClone.classList.add('modal', 'modal--clone');
			modalClone.classList.remove('hidden');
			fragment.append(section, modalClone);
		}

		fragment.append(section);
	}

	const promise = new Promise((resolve) => {
		resolve([projects.order, fragment]);
	});

	return promise;
};

const displayJobs = async (job, isPreview) => {
	let content = document.createDocumentFragment();

	if (isPreview) {
		job.forEach((el) => {
			const container = document.createElement('div');
			container.className = 'position textblock';
			container.setAttribute('data-animation', 'viewport');

			let button = {
				link: el.link,
				transition: 'default',
				text: 'Stelle ansehen',
			};

			let textblock = createTextblock(
				el.acf.jobtitel,
				'h2',
				el.acf.infos,
				el.acf.einleitung,
				button
			);
			textblock.querySelector('h2').classList.add('multi-line');

			container.append(textblock);
			content.appendChild(container);
		});
	} else {
		if (!job.acf) {
			return;
		}

		let section = createElement({
			node: 'section',
			id: job.type,
		});

		section.setAttribute('data-preview', 'false');

		var intro = document.createElement('div');
		intro.setAttribute('data-animation', 'viewport');

		let entryHeader = createElement({
			node: 'div',
			class: 'entry-header',
		});

		let container = document.createElement('div');
		container.setAttribute('data-animation', 'viewport');

		let textblock = createTextblock(
			job.acf.jobtitel,
			'h1',
			job.acf.infos,
			job.acf.einleitung
		);
		textblock.querySelector('.title').classList.add('multi-line');

		container.append(textblock);

		entryHeader.append(container);

		var stickyScroll = document.createElement('div');
		stickyScroll.className = 'sticky-scroll';

		var entries = document.createElement('div');
		entries.className = 'jobbeschreibungen';

		var imgWrapper = document.createElement('div');
		imgWrapper.className = 'sticky-image';
		var stickyImg = document.createElement('img');
		stickyImg.className = 'illustration';
		stickyImg.setAttribute('data-animation', 'viewport');
		addSourceSet(stickyImg, job.acf.illustration, 40, 100);
		imgWrapper.append(stickyImg);

		for (const jobbeschreibung of job.acf.jobbeschreibung) {
			let textblock = document.createElement('div');
			textblock.className = 'textblock';
			textblock.setAttribute('data-animation', 'viewport');

			let innerBlock = createTextblock(
				jobbeschreibung.ueberschrift,
				'h2',
				jobbeschreibung.liste,
				jobbeschreibung.text
			);

			innerBlock.querySelector('h2').classList.add('multi-line');

			textblock.append(innerBlock);
			entries.append(textblock);
		}

		let cta = document.createElement('div');

		await displayCTA(job.acf.ansprechpartner, null).then((response) => {
			cta = response[1];
		});

		stickyScroll.append(imgWrapper, entries);
		section.append(entryHeader, stickyScroll, cta);
		content.appendChild(section);
	}

	const promise = new Promise((resolve) => {
		resolve([0, content]);
	});

	return promise;
	// return content;
};

async function renderPageLinks(data) {
	const pagelinkIds = data.map((e) => e.seitenauswahl);

	const query = await queryData({
		type: 'pages',
		id: pagelinkIds.join(','),
	}).then((content) => {
		return content;
	});

	var pagelinksContent = [];

	// loop over pagelinkElements to preserver pagelink order as in WP backend
	for (const content of data) {
		const heroData = query.find((e) => e.id == content.seitenauswahl);

		const pageLink = createElement({
			node: 'section',
			class: 'intro pagelink',
		});
		pageLink.setAttribute('data-preview', true);
		pageLink.setAttribute('data-transition-keep', '');

		const container = document.createElement('div');
		container.setAttribute('data-animation', 'viewport');

		const introContainer = createElement({
			node: 'div',
			class: 'introContainer',
		});
		introContainer.append(
			createHeader('h1', heroData.acf.modul[0].ueberschrift)
		);
		introContainer.append(createGraphics(heroData.slug, 'illustrations'));

		const button = {
			link: heroData.link,
			text: content.buttontext,
			transition: 'page',
		};
		const btn = createButton(button);

		btn.addEventListener('mouseenter', () =>
			pageLink.classList.add('show-illustrations')
		);
		btn.addEventListener('mouseleave', () =>
			pageLink.classList.remove('show-illustrations')
		);

		const text = createElement({ node: 'div', class: 'text' });
		text.append(createParagraph(heroData.acf.modul[0].text), btn);

		container.append(introContainer, text);
		pageLink.append(container);

		pagelinksContent.push([content.order, pageLink]);
	}

	return pagelinksContent;
}

function jumpToMember(e) {
	e.preventDefault();
	const member = e.target.dataset.member;

	const { id } = getPageData(`/team/${member}/`);

	window.history.replaceState(
		{ link: '/team/' + member + '/' },
		'',
		'/team/' + member + '/'
	);

	let data = queryData({
		id: id,
		type: 'team',
		fields: ['yoast_head_json'],
	}).then((data) => {
		SEO.update(data[0].yoast_head_json);
	});

	document.querySelector('#' + member).scrollIntoView({
		behavior: 'smooth',
		block: 'center',
	});

	return false;
}
