import { queryData } from './_content.js';
import { Button, ServicesListing } from './_components.js';
import { siteUrl, pathMap } from './_page.js';
import { createElement, createHeader, createParagraph, createGraphics, addSourceSet } from './_createElements.js';


export class TaxonomyCluster {
	constructor(data) {
		this.DOM = {}
		this.data = data;
	}
	
	createCluster() {
		let content = '';

		for (const item of this.data.taxonomy_selector) {
			/* let query = queryData({
				id: item.ID,
				type: item.post_type,
				fields: ['link']
			})

			query.then(response => {
				response = response[0];
			}) */
			
			let button = new Button({
				text: item.post_title,
				link: siteUrl + '/agentur/' + item.post_name + '/',
				transition: 'default'
			})	
			
			content += button.render()	
		}

		return content;
	}

	render() {
		let fragment = document.createElement('template');

		fragment.innerHTML = `
			<div class="taxonomy-cluster__heading" data-animation="viewport">
				<h2 class="title">${ this.data.heading }</h2>
			</div>

			<div class="taxonomy-cluster__body button-group-container" data-animation="viewport">
				${ this.createCluster() }
			</div>
		`;

		return fragment.content;
	}
}

export class ImageTextBlock{
	constructor(data) {
		this.data = data;
	}

	createGallery(data) {
		let content = '';
		const windowBar = `
			<div class="window-bar">
				<div class="window-bar-dots">
					<div class="window-bar-dot"></div>
					<div class="window-bar-dot"></div>
					<div class="window-bar-dot"></div>
				</div>
			</div>
		`;
		
		for (const item of data) {
			let container = document.createElement('div');

			switch(item.acf_fc_layout) {
				case 'image':
				case 'video':
					container.className = 'media-container';

					if (item.border_option) {
						container.classList.add('has-border');

						container.innerHTML += windowBar;

					}

					let media;

					switch(item.file.type) {
						case 'image': 
							media = new Image();
							media.src = item.file.url
							
							if (item.file.alt) {
								media.alt = item.file.alt
							}
							
							break;
							
						case 'video': 
							media = document.createElement('video');
							media.src = item.file.url
							media.setAttribute('muted', true)
							
							break;
					}
					
					container.appendChild(media)

				break;

				case 'mobile_screens':
					container.className = 'mobile-gallery'
					
					for (const media of item.files) {

						let fragment = `
							<div class="media-container has-border">
								${ windowBar }
								${ media.type === 'video' ? '<video src="' + media.url + '" muted="true">' : '' }
								${ media.type === 'image' ? '<img src="' + media.url + '"' + (media.alt ? 'alt="' + media.alt + '">' : '') : '' }
							</div>
						`;

						container.innerHTML += fragment;
					}

					break;
			}
			
			content += container.outerHTML
		}

		return content;
	}

	render() {
		let fragment = document.createElement('template');

		fragment.innerHTML = `
			<div class="image-text-block__text" data-animation="viewport">
				<div class="textfield">
					${ this.data.text }
				</div>
			</div>

			<div class="image-text-block__gallery" data-animation="viewport">
				${ this.data.gallery ? this.createGallery(this.data.gallery) : ''}
			</div>
		`;

		return fragment.content;
	}
}

export class NewsArticle {
	constructor(data) {
		this.data = data;
	}
}


export class ImageTextServices {
	constructor(data) {
		this.data = data;
		this.ServicesList = new ServicesListing(data.textblock.services);
	};

	render() {
		let fragment = document.createElement("template");
		fragment.innerHTML = `
			<section class="block image-text-services">
				<div class="image-text-services__image sticky-image" data-animation="viewport">
					<img class="illustration" src="${this.data.illustration.url }" alt="${this.data.illustration.alt}">
				</div>
				
				<div class="col image-text-services__content" data-animation="viewport">
					<div class="textblock">
						<h2 class="text-mask title">
							<span class="mask">
								<span>${this.data.textblock.headline}</span>
							</span>
						</h2>

						${ this.ServicesList.render() }

						<div class="text">
							${ this.data.textblock.content }
						</div>
					</div>
				</div>
			</section>
		`;
		return fragment.content;
	}
}

export function renderHeroBlock(element, page) {
	var wrapper = createElement({ node: 'section', class: 'intro fade-in' })
	wrapper.setAttribute('data-transition', 'target');

	var container = document.createElement('div');
	container.setAttribute('data-animation', 'viewport');

	var title = createHeader('h1', element.ueberschrift)

	var text = createElement({ class: 'text' })

	var paragraph = createParagraph(element.text)
	text.append(paragraph);

	var introContainer = createElement({ class: 'introContainer' })

	var graphics = createGraphics(page.slug, 'both');

	if (element.bilder) {
		var gallery = createElement({ class: 'gallery' })

		element.bilder.forEach((value, index) => {
			let container = createElement({ class: 'image-container' })
			let content = createElement({ node: 'img', class: 'image-content' })
			addSourceSet(content, element.bilder[index], 20, 40);
			container.append(content);
			gallery.append(container);
		})
	} else {
		var gallery = '';
	}

	introContainer.append(title, gallery, graphics)
	container.append(introContainer, text)
	wrapper.append(container)

	const promise = new Promise((resolve) => {
		resolve([element.order, wrapper])
	})

	return promise;
}