import { isMobile } from "./_page.js";

export const videoObserver = new IntersectionObserver(function (
	entries,
	videoObserver
) {
	entries.forEach(entry => {
		if (!entry.isIntersecting) {
			entry.target.pause();
		} else {
			entry.target.play();
		}
	});
}, {
	threshold: 1,
});

export const viewportObserver = new IntersectionObserver(function (
	entries,
	viewportObserver
) {
	entries.forEach(entry => {
		if (!entry.isIntersecting) {
			return;
		} else {
			entry.target.removeAttribute('data-animation');
			entry.target.classList.add('isInViewport');
		}
	});
}, {
	threshold: 0,
	rootMargin: `0px 0px ${window.innerHeight * 0.125}px 0px`
});

export const sliderObserver = new IntersectionObserver(function (
	entries,
	sliderObserver
) {
	if (!isMobile()) {return;}
	entries.forEach(entry => {
		if (!entry.isIntersecting) {
			entry.target.classList.remove("active");
		} else {
			entry.target.classList.add("active");
		}
	});
}, {
	root: document.querySelector('.entries'),
	rootMargin: "100% 0px 100% 0px",
	threshold: 0.5
});

export const teamObserver = new IntersectionObserver(function (
	entries,
	teamObserver
) {
	if (isMobile()) {return;}
	entries.forEach(entry => {
		const jumpMarker = document.querySelector(`.jumpMarkerTeam[data-member="${entry.target.id}"]`)

		if (!entry.isIntersecting) {
			jumpMarker.classList.remove("active");
		} else {
			jumpMarker.classList.add("active");
		}
	});
}, {
	root: document.querySelector('.entries'),
	// rootMargin: "100% 0px 100% 0px",
	threshold: 0.8
});

