let isMobile = window.matchMedia("(max-width: 800px)").matches;

export function disableScroll() {
	document.documentElement.style.overflow = 'hidden';
}
export function enableScroll() {
	document.documentElement.style.overflow = null;
}

// update horizontal Scroll
const rotateProjects = (event) => {
	if (!document.querySelector('main.startseite')) return;

	let projects = document.querySelectorAll('main.startseite #projekte .project');
	switch (event.type) {
		case 'change':
			if (!event.matches) {
				document.querySelectorAll('main.startseite #projekte .project').forEach((el, i) => {
					el.style.transform = 'unset';
				})
			}
			break;
		case 'scroll':
			let positions = [
				{
					'rotation': '10deg',
					'translateX': '10vw',
					'translateY': '6vw'
				}, {
					'rotation': '-10deg',
					'translateX': '-10vw',
					'translateY': '12vw'
				}, {
					'rotation': '0deg',
					'translateX': '0vw',
					'translateY': '-6vw'
				}
			]

			let container = document.querySelector('main.startseite #projekte');
			let containerCenter = container.getBoundingClientRect().top + container.clientHeight / 2;

			let startY = (0 + window.innerHeight) + container.clientHeight / 2;
			let endY = window.innerHeight / 2;
			let percentage;
			let range = endY - startY;
			let correctedStartValue;
			if (containerCenter < startY & containerCenter > endY) {
				correctedStartValue = containerCenter - startY;
				percentage = (correctedStartValue * 100) / range;
				projects.forEach((el, i) => {
					let properties = positions[i];
					let rotation = 'rotateZ(calc(' + properties.rotation + ' * ' + (percentage / 100) + '))';
					let translate = 'translate3D(calc(' + properties.translateX + ' * ' + (percentage / 100) + '), calc(' + properties.translateY + ' * ' + (percentage / 100) + '), 0)';
					el.style.transform = 'perspective(100vw) ' + translate + ' ' + rotation;
				})
			}
			break;
	}
}

document.addEventListener("DOMContentLoaded", () => {
	window.matchMedia('(max-width: 800px)').onchange = function (event) {
		isMobile = event.matches
		rotateProjects(event)

		//  prevent hidden Items on Team Page to blip
		if (document.querySelector('main.team')) {
			document.querySelectorAll('.gallery, .hidden-text, .thumbnail').forEach(el => {
				el.classList.add('no-transition');
				setTimeout(() => {
					el.classList.remove('no-transition');
				}, 100)
			})
			if (!document.querySelector('.show-content')) return;
			document.querySelector('.show-content').classList.remove('show-content')
		}
	};

	window.matchMedia('(max-width: 1024px)').onchange = function (event) {
		if (document.body.hasAttribute('data-show-modal')) {
			document.body.toggleAttribute('data-show-modal');
			enableScroll();
		}
	};

	// Detect change on Body Element
	const body = document.body;
	const main = document.querySelector('main');
	const options = {
		attributes: true,
	}

	function callback(mutationList, observer) {
		mutationList.forEach(function (mutation) {
			if (mutation.type === 'attributes' && mutation.target.nodeName === 'BODY') {
				if (mutation.attributeName !== 'data-show-nav' && mutation.attributeName !== 'data-show-modal') return;

				document.addEventListener('transitionend', function trackAnimation(e) {
					if (e.target.nodeName === 'NAV' || e.target.id === 'modal') {
						if (e.propertyName !== 'clip-path') return;
						document.removeEventListener('transitionend', trackAnimation);
						document.body.removeAttribute('data-is-animating');

						if (!document.body.dataset.hasOwnProperty('showNav') && !document.body.dataset.hasOwnProperty('showModal')) {
							enableScroll();
						}
					}
				});

				if (document.body.hasAttribute(mutation.attributeName)) {
					disableScroll();
				}

				document.body.setAttribute('data-is-animating', '');
			}
		})
	}

	const bodyChange = new MutationObserver(callback)
	bodyChange.observe(body, options)

	const htmlChange = new MutationObserver(callback)
	htmlChange.observe(document.documentElement, options)


	document.addEventListener('click', function toggleMenu(e) {
		/* Open Nav */
		if (e.target.classList.contains('button--menu')) {
			document.body.toggleAttribute('data-show-nav');
		}

		/* Open Modal */
		if (e.target.classList.contains('button--modal')) {
			document.body.toggleAttribute('data-show-modal');
			document.querySelector('#modal').classList.toggle('hidden')
		}
	})

	// Event Animations
	window.addEventListener('scroll', (e) => {
		if (isMobile) {
			rotateProjects(e)
		}
	})
})

