/* Imports */
import { queryData, renderData } from './_content.js';
import { changePage } from './_transitions.js';
import { viewportObserver } from './_scrollEvents.js';
import { disableScroll, enableScroll, listenToScrollEvent } from './_navi.js';

/* Variables */
export const siteUrl = "https://" + top.location.host.toString();
let query = window.matchMedia("(max-width: 800px)");

export const isMobile = () => {
	return query.matches;
};

window.addEventListener("resize", () => {
	isMobile();
})

window['isAnimating'] = false

const main = document.querySelector('main');
const loader = document.getElementById('loader');

let initialLoad = true;
let fadingPromise, fadingPromiseResolve, fadingPromiseReject;
let loaderTimer;
let fadingDuration = 500; // Value: ms
const pathMap = JSON.parse(myVar.pathMapJson);

window['hasTransitioned'] = true;
let storedScrollPosition;
let newScrollPosition;
let isPageTransition = false;
export var ready = true;

/* Object Classes */
class SEOHead {
	constructor() {
		this.title = document.head.querySelector('title');
		this.description = document.head.querySelector('[name="description"]');
		this.robots = document.head.querySelector('[name="robots"]');
		this.canonical = document.head.querySelector('[rel="canonical"]');
		this.schema = document.head.querySelector('[type="application/ld+json"]')

		this.og_title = document.head.querySelector('[property="og:title"]');
		this.og_type = document.head.querySelector('[property="og:type"]');
		this.og_locale = document.head.querySelector('[property="og:locale"]');
		this.og_description = document.head.querySelector('[property="og:description"]');
		this.og_url = document.head.querySelector('[property="og:url"]');
		this.og_site_name = document.head.querySelector('[property="og:site_name"]');

		this.og_image = {
			url: document.head.querySelector('[property="og:image"]'),
			type: document.head.querySelector('[property="og:image:type"]'),
			width: document.head.querySelector('[property="og:image:width"]'),
			height: document.head.querySelector('[property="og:image:height"]'),
		}
	}

	update(data) {
		// Check which tags remain and sort out older ones
		for (const key in this) {
			if (this.hasOwnProperty.call(this, key)) {
				let tag = this[key];

				if (key === 'og_image') {
					for (const subKey in tag) {
						if (!data[key] && tag[0][subKey]) {
							tag.remove();
						}
					}
				}

				if (!data[key] && tag) {
					tag.remove();
				}
			}
		}

		for (const key in data) {
			if (data.hasOwnProperty.call(data, key)) {
				let value = data[key];
				let tag = this[key];

				switch (key) {
					case 'title':
						if (value && !tag) {
							tag = document.createElement('title');
						}
						tag.innerText = value;
						break;

					case 'canonical':
						if (value && !tag) {
							tag = document.createElement('link');
							tag.rel = key;
						}
						tag.href = value;
						break;

					case 'robots' || 'description':
						if (myVar.dontIndexMe) break;
						if (value && !tag) {
							tag = document.createElement('meta');
							tag.name = key;
						}

						if (key === 'robots') {
							let map = []
							for (const item in value) {
								if (value.hasOwnProperty.call(value, item)) {
									const element = value[item];
									map.push(element);
								}
							}

							value = map.join(',');
						}
						tag.content = value;
						break;

					case 'schema':
						if (value && !tag) {
							tag = document.createElement('script');
							tag.type = 'application/ld+json';
						}
						tag.innerText = JSON.stringify(value);
						break;

					case 'og_image':
						for (const key2 in value[0]) {
							if (value[0].hasOwnProperty.call(value[0], key2)) {
								let item = value[0][key2];

								if (item && !this[key][key2]) {
									tag = document.createElement('meta');
									tag.property = 'og:image';
									if (key2 !== 'url') {
										tag.property += ':' + key2;
									}
								}

								tag.content = item;
							}
						}
						break;

					default:
						if (value && !tag) {
							tag = document.createElement('meta');
							if (key.includes('og')) key.replace(':', '_')
							tag.property = key;
						}
						tag.content = value;
						break;
				}

			}
		}

		let plausibleScript = document.querySelector('head script[data-domain]');
		if (!plausibleScript || initialLoad) return;
		document.querySelector('head').appendChild(plausibleScript.cloneNode(false))
		plausibleScript.remove();
	}
}

export let SEO = new SEOHead();

// Start first contact when window is visible
let windowIsFocused = !document.hidden;

// Starts the page query for a given pathname
export function handlePath(path, doFadeOut = true, keepFirst = false) {
	// Start the loader animation after 2 seconds
	loaderTimer = setTimeout(() => {
		fadeIn(loader, 200);
	}, 2000)

	// Get the page properties
  	const pageProperties = pathMap[path];

	// Set the body dataset based on the page type
	if (initialLoad && pageProperties) {
		document.body.dataset.pageType = pageProperties.type
	}

	// Query the page data and display the content
	if (pageProperties?.id && pageProperties?.type) {
		queryPage(pageProperties, keepFirst, false, history);
	} else {
		handlePathFallback(path, keepFirst);
	}
}

// Querys a page to display its content according to the pagebuilder standard
const queryPage = async (properties, keepFirst, is404) => {
  const id = properties.id;
  const type = (properties.type === 'page') ? 'pages' : properties.type;

  const response = await fetch(`${window.location.origin}/wp-json/wp/v2/${type}/?include[]=${id}`);
  const page = await response.json();

  replaceContent(page[0], id, !is404, keepFirst, false);
};

/* async function queryData() {
	let queryString = '';
	let queryFilter = [];

	params.type = (params.type === 'page') ? 'pages' : params.type;

	let query = {
		// type: params.type ? params.type : '',
		id: params.id ? 'include=' + params.id : '',
		slug: params.slug ? 'slug=' + params.slug : '',
		category: params.category ? 'categories=' + params.category : '',
		quantity: params.quantity ? 'per_page=' + params.quantity : '',
		fields: params.fields ? '_fields=' + params.fields.join(',') : '',
		status: 'status=publish'
	};

	for (const key in query) {
		if (Object.hasOwnProperty.call(query, key)) {
			const element = query[key];
			if (element.length === 0) continue;
			queryFilter.push(element)
		}
	}

	queryString += queryFilter.join('&');

	let data = await fetch(
		window.location.origin + "/wp-json/wp/v2/" + params.type + '?' + queryString
	)

	return data.json()
} */

const replaceContent = async (page, id, doChangeHistory, keepFirst = false, restoreScroll) => {
	if (page.yoast_head_json || !initialLoad) {
		SEO.update(page.yoast_head_json)
	}
	storedScrollPosition = document.documentElement.scrollTop;

	// Load content while fading out
	let contentPromise = renderData(page);

	// Fade in after content loading AND fading out is done
	await contentPromise.then(async function (results) {
		clearTimeout(loaderTimer);
		fadeOut(loader, 200);

		await waitFor(_ => window['isAnimating'] === false);
		main.innerHTML = "";
		insertContent(results, page, restoreScroll)
		return;
	});
}

// Select the target node
const targetNode = document.querySelector("main");

// Create a new instance of the MutationObserver
const observer = new MutationObserver(function(mutationsList, observer) {
    for (const mutation of mutationsList) {
        if (mutation.type === 'childList') {
			if ( mutation.target.childNodes.length === 0 ) return;
			setTimeout(() => {
				document.body.classList.add('is-ready');
			}, 25)
		
			setTimeout(() => {
				enableScroll();
			}, 1000)

			loadHighResImages()
			watchAllInternalLinks();

			const fadeTargets = document.querySelectorAll("*[data-animation='viewport']");

			fadeTargets.forEach(el => {
				viewportObserver.observe(el)
			});
        }
    }
});

// Configure the observer to only listen for additions to the target node
const config = { childList: true };

// Start observing the target node
observer.observe(targetNode, config);

async function insertContent(content, page, restoreScroll) {
	await waitFor(_ => windowIsFocused === true);

	if (!hasTransitioned) await waitFor(_ => hasTransitioned === true);

	main.className = page.slug;
	main.appendChild(content);

	if (restoreScroll == true) {
		window.scrollTo(0, newScrollPosition)
	} else {
		window.scrollTo(0, 0)
	}

	await waitFor(_ => ready === true);

	document.body.dataset.pageType = page.type;

	backToModal();


	initialLoad = false;
	document.body.classList.remove('initial-load');
}

// https://stackoverflow.com/a/3261380/2170318
// https://developer.mozilla.org/en-US/docs/Glossary/Falsy
const isEmpty = (str) => {
	return (!str || str.length === 0);
}

// Fallback function, maybe not even needed anymore
const handlePathFallback = (path, keepFirst) => {
	// console.warn("Path information for <" + path + "> missing from cache, using fallback.");

	// In this case we are on the front page
	if (path == "/") {
		getFrontpageId().then(
			result => {
				queryPage(result.data.ID, result.data.Type, keepFirst, false);
			})
			.catch(err => console.error(err));
	}
	// Otherwise we are on a normal page
	else {
		getPostId(path).then(
			result => {
				if (result.data.ID == 0) {
					// console.error("PostId is " + result.data.ID + ". A postId of 0 means post is not a page.");

					let properties =

						queryPage({ id: 260, type: 'page' }, false, true);
					// throwError404();
				}
				else {
					queryPage(result.data.ID, result.data.Type, keepFirst, false);
				}
			})
			.catch(err => console.error(err));
	}
}

// Gets the Wordpress postID by ajax call.
const getPostId = async (postUrl) => {
	try {
		const response = await fetch('/wp-admin/admin-ajax.php', {
			method: 'POST',
			body: new URLSearchParams({
				action: 'get_postid',
				url: postUrl
			})
		});

		if (response.ok) {
			const data = await response.json();
			return data;
		} else {
			throw new Error('Network response was not ok');
		}
	} catch (error) {
		return 'Some error occurred.';
	}

}

// Gets the ID of the Wordpress front page by ajax call.
const getFrontpageId = () => {
	const result = fetch('/wp-admin/admin-ajax.php', {
		method: 'POST',
		body: new URLSearchParams({
			action: 'get_frontpageid'
		})
	})
	.then(response => {
		if (response.ok) {
			return response.json();
		} else {
			throw new Error('Network response was not ok');
		}
	})
	.then(data => {
		return data;
	})
	.catch(error => {
		return error;
	});

	return result;

}

// Throws a 404 error
const throwError404 = async () => {
	try {
		const response = await fetch('/wp-admin/admin-ajax.php', {
			method: 'POST',
			body: new URLSearchParams({
				action: 'throw_error'
			})
		});

		if (response.ok) {
			return true;
		} else {
			throw new Error('Network response was not ok');
		}
	} catch (error) {
		return error;
	}

}

export function waitFor(conditionFunction) {
	const poll = resolve => {
		if (conditionFunction()) resolve();
		else setTimeout(_ => poll(resolve), 125);
	}
	return new Promise(poll);
}

/* Back to Modal Scroll Event */
function backToModal() {
	if (document.body.dataset.pageType !== 'projekte' || document.getElementById("projekte")?.dataset.category === "5" || document.getElementById("projekte")?.dataset.category === "3") {
		return;
	}
	const modal = document.querySelector('header #modal');
	const projectId = document.querySelector('.project').dataset.id;

	const modalClone = document.querySelector('.modal--clone');

	const image = document.querySelector('.background-image');
	const credits = document.querySelector('.credits');

	const targetCard = document.querySelector('header #modal [data-id="' + projectId + '"]')

	let progress;

	window.matchMedia("(max-width: 1240px)").onchange = function (e) {
		image.style.inset = null;
		image.style.borderRadius = null;
		image.style.display = 'block'
		image.style.setProperty('--overlay-color', 'rgba(38, 38, 38, 0.65)')
	}

	window.addEventListener('scroll', (e) => {
		if (!document.querySelector('.project') || window.matchMedia("(max-width: 1240px)").matches) {
			return;
		}

		if (credits.getBoundingClientRect().top > 0) {
			let inset = {
				left: document.documentElement.clientWidth - window.innerWidth
			}

			image.style.transition = null;
			image.style.borderRadius = null;

			document.querySelector('body[data-page-type="projekte"] .project').style.pointerEvents = 'auto'
			image.style.inset = `0 0 0 0`;
			return;
		}

		if (document.querySelector('body[data-page-type="projekte"] .project')) {
			document.querySelector('body[data-page-type="projekte"] .project').style.pointerEvents = 'none'
		}

		image.style.setProperty('--duration', 'unset')

		image.style.transition = 'unset';

		let windowHeight = window.innerHeight;

		progress = Math.min((credits.getBoundingClientRect().top * (-1)) / windowHeight, 1);

		if (modalClone && progress >= 0.0007) {
			modalClone.style.visibility = 'visible'
		}

		let target = {
			top: targetCard.getBoundingClientRect().top,
			right: targetCard.getBoundingClientRect().right,
			bottom: targetCard.getBoundingClientRect().bottom,
			left: targetCard.getBoundingClientRect().left
		}

		let offset = {
			top: target.top * progress,
			right: (document.documentElement.clientWidth - target.right) * progress,
			bottom: (windowHeight - target.bottom) * progress,
			left: target.left * progress,
		}

		let opacity = 0.65 * (1 - progress);

		image.style.inset = `${offset.top}px ${offset.right}px ${offset.bottom}px ${offset.left}px`;
		image.style.borderRadius = (4 * progress) + 'px';
		image.style.setProperty('--overlay-color', 'rgba(38, 38, 38, ' + opacity + ')')

		if (progress >= 1) {
			image.style.display = 'none';
		} else if (progress < 1 && image.style.display == 'none') {
			image.style.display = 'block'
		}
	})
}

export function getPageData(path) {
	return pathMap[path];
}

function fadeIn(element, duration) {
	element.style.display = "block";
	element.style.opacity = 0;
	let start = performance.now();

	function animate(currentTime) {
		let elapsedTime = currentTime - start;
		element.style.opacity = Math.min(1, elapsedTime / duration);

		if (elapsedTime < duration) {
			requestAnimationFrame(animate);
		}
	}

	requestAnimationFrame(animate);
}

function fadeOut(element, duration) {
	element.style.opacity = 1;
	let start = performance.now();

	function animate(currentTime) {
		let elapsedTime = currentTime - start;
		element.style.opacity = Math.max(0, 1 - elapsedTime / duration);

		if (elapsedTime < duration) {
			requestAnimationFrame(animate);
		} else {
			element.style.display = "none";
		}
	}

	requestAnimationFrame(animate);
}

function watchAllInternalLinks() {
	document.querySelectorAll("a[href^='" + siteUrl + "']").forEach(el => {
		if (el.href.includes(siteUrl)) {
			el.addEventListener('click', (e) => {
				e.preventDefault();

				if (document.body.hasAttribute('data-show-nav') || document.body.hasAttribute('data-show-modal')) {
					if (el.pathname !== window.location.pathname) {
						document.querySelector('main').innerHTML = null
					}
				}

				if (document.body.hasAttribute('data-show-nav')) {
					delete document.body.dataset.showNav;
				}

				delete document.body.dataset.showModal;

				document.querySelector('#modal').classList.add('hidden')

				if (el.pathname == window.location.pathname) {
					document.documentElement.scrollIntoView({
						block: 'start',
						behavior: 'smooth'
					});

					return;
				}

				document.body.classList.remove('is-ready');

				if (!pathMap[el.pathname]) {
					handlePathFallback()
					return
				}
				window.history.pushState({ "link": el.pathname, "id": pathMap[el.pathname].id }, "", el.pathname);

				if (el.dataset.transition) {
					changePage(el);
				}

				return false;
			}, true);
		}
	})
}

function loadHighResImages() {
	const targets = document.querySelectorAll('*[data-src]');

	targets.forEach(el => {
		const newSrc = el.dataset.src;
		el.src = newSrc;
		delete el.dataset.src;
	})
}

document.addEventListener("visibilitychange", function handleVisibilityChange() {
	if (document.visibilityState === "visible") {
		windowIsFocused = true
		document.removeEventListener("visibilitychange", handleVisibilityChange, false);
	}
}, false);

document.addEventListener('DOMContentLoaded', () => {
	// Adapt Favicon to Color Mode
	if (window.matchMedia("(prefers-color-scheme: dark)").matches) {
		// Dark Mode
		let canvas = document.createElement('canvas'), context = canvas.getContext('2d');
		canvas.height = 64;
		canvas.width = 64;
		let favicon = document.createElement('link');
		favicon.rel = "icon";
		favicon.type = "image/png";
		document.getElementsByTagName('head')[0].appendChild(favicon);

		let base_image = new Image();
		base_image.src = myVar.templateDir + '/img/favicon_augen_light.svg';
		base_image.onload = function () {
			context.drawImage(base_image, 0, 0, 64, 64);
			favicon.href = canvas.toDataURL("svg/xml");
		}
	}

	// Scroll Restoration
	if ('scrollRestoration' in history) {
		history.scrollRestoration = 'manual';
	}

	// Triggered visiting url directly
	handlePath(window.location.pathname, false);
	window.history.replaceState({ "link": window.location.pathname, "id": pathMap[window.location.pathname].id }, "", window.location.pathname);
})

// Handles browser forward / back stack
window.onpopstate = function (e) {
	if (!e.state) return;

	if (!e.state.page) {
		console.warn('NO STATE PAGE')
	}

	if (!e.state.link) {
		console.warn('NO STATE LINK')
	}
	newScrollPosition = storedScrollPosition;

	document.body.classList.remove('is-ready');

	window['isAnimating'] = true;
	setTimeout(() => {
		window['isAnimating'] = false;
	}, 1000);

	if (document.body.hasAttribute('data-show-nav') || document.body.hasAttribute('data-show-modal')) {
		if (this.pathname !== window.location.pathname) {
			document.querySelector('main').innerHTML = null
		}
	}

	if (!document.body.hasAttribute('data-show-nav') || !document.body.hasAttribute('data-show-modal')) {
		delete document.body.dataset.showNav;
		delete document.body.dataset.showModal;
	}

	document.body.classList.remove('is-ready')

	if (e.state.page) {
		replaceContent(e.state.page, e.state.id, false, false, true);

	} else if (e.state.link) {
		handlePath(e.state.link, false, false, false)
	}

	return false;
};