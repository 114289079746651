export class Button {
	constructor(data) {
		this.data = data;
	}

	render() {
		return `<a href="${this.data.link}" class="btn" ${this.data.transition ? 'data-transition="' + this.data.transition + '"' : '' }>
				<span class="btn-inner">
					<span class="btn-inner__text">${this.data.text}</span>
					<span class="btn-inner__hover" data-nosnippet>${this.data.text}</span>
				</span>
			</a>`;
	}
}

export class ServicesListing {
	constructor(data) {
		this.data = data;
		// console.log(this.data);
		this.Listing = this.createList(this.data);

	}

	createList(array) {
		let list = "";
		array.forEach(element => {
			const listItem = `
				<li class="text-mask">
					<a href="${ element.url ? element.url : '' }" data-transition="default">${ element.title }</a>
				</li>
			`;

			list += listItem;
		});

		return list;
	}

	render() {
		return `
			<ul class="list">
				${ this.Listing }
			</ul>
		`
	}
}