// import { ready } from './page.js';
import { disableScroll, enableScroll } from './_navi.js';
import { SEO, pathMap, handlePath, waitFor } from './_page.js';
import { queryData } from './_content.js';
let isMobile = window.matchMedia("(max-width: 800px)").matches;

window.matchMedia('(max-width: 800px)').onchange = function (event) {
	isMobile = event.matches
}

let ready;

export async function changePage(source) {
	disableScroll();
	ready = false;
	window['isAnimating'] = true;

	setTimeout(() => {
		window['isAnimating'] = false;
		document.querySelector('main').innerHTML = null;
	}, 1000) // <-- duration of page transition

	let clone;
	let target;

	switch (source.dataset.transition) {
		case 'default':
			if (document.querySelector('.modal--clone')) {
				document.querySelector('.modal--clone').classList.add('hidden')
			}
			handlePath(source.pathname, false, false)
			ready = true

			break;

		case 'page':
			// request Content
			source.style.pointerEvents = 'none';
			handlePath(source.pathname, false, false)

			// ### SCHRITT #1 ###
			source = source.closest('[data-transition-keep]')

			// create Clone
			clone = new IntroClone(source);

			clone.create();
			clone.append();

			await waitFor(_ => !window['isAnimating']);
			await waitFor(_ => document.querySelector('[data-transition="target"]'));
			ready = true
			target = document.querySelector('[data-transition="target"]');
			target.dataset.transitionKeep = 'passive';
			target.querySelector('.text p').style.transform = 'unset';
			clone.move(target);

			target.addEventListener('transitionend', function toEndItAll(e) {
				if(e.propertyName === 'transform') {
					target.removeEventListener('transitionend', toEndItAll);
					target.querySelector('.text p').style.transform = null;
					target.dataset.transitionKeep = 'active';

					setTimeout(() => {
						clone.remove();
					}, 125)
				}
			})

			break;
		
		case 'project':
			// request Content
			handlePath(source.pathname, false, false)

			// wait for new page content
			if (source.getElementsByTagName('img').length === 0) {
				source = source.closest('.project').querySelector('.image-container')
			}

			// create Clone
			clone = new Clone(source);

			await waitFor(_ => !window['isAnimating']);
			await waitFor(_ => document.querySelector('.background-image'))
			target = document.querySelector('.background-image');

			// hide background 
			target.style.visibility = 'hidden'
			await waitFor(_ => target.style.visibility)

			setTimeout(() => {
				ready = true;
				clone.enlargen();
			}, 250)

			await waitFor(_ => document.querySelector('.clone').hasAttribute('data-enlargen') );
			document.querySelector('.clone[data-enlargen]').addEventListener('transitionend', (e) => {
				target.style.visibility = null;
			})

			break;
		
		case 'modal':
			// request Content
			handlePath(source.pathname, false, false)

			// create & animate Clone
			clone = new Clone(source, 'modal');
			
			if (document.querySelector('.modal--clone')) {
				document.querySelector('.modal--clone').classList.add('hidden')
			}

			// wait for new page content
			await waitFor(_ => window['isAnimating'] === false);
			await waitFor(_ => document.querySelector('[data-transition="target"]'))

			target = document.querySelector('[data-transition="target"].background-image');

			// hide background image
			target.style.visibility = 'hidden'
			await waitFor(_ => target.style.visibility)
			
			// restore Card visibility
			document.querySelectorAll('.modal .card').forEach(el => {
				el.style.visibility = null;
			})

			setTimeout(() => {
				ready = true;
				clone.DOM.clone.style.zIndex = 10
				clone.enlargen();
			}, 250)

			break;

		case 'team':
			transitionImage(source);
			if (!isMobile) {
				transitionHeading(document.querySelector('.heading'))
			}
			handlePath(source.pathname, false, false)

			break;
	}
}

class Clone {
	constructor(el, location) {
		this.location = location;

		this.DOM = {
			el: el,
			img: el.querySelector('img').cloneNode(true),
		};

		this.values = {
			imageWidthStart: window.innerWidth,
			imageHeightStart: window.innerHeight,
			imageWidthEnd: el.clientWidth,
			imageHeightEnd: el.clientHeight,

			scale: this.DOM.el.querySelector('img').getBoundingClientRect().width / this.DOM.el.querySelector('img').offsetWidth,

			posX: el.getBoundingClientRect().left,
			posY: el.getBoundingClientRect().top,

			ratioBrowser: window.innerWidth / window.innerHeight,
			ratioImage: this.DOM.img.naturalWidth / this.DOM.img.naturalHeight
		};

		this.image = {};
		this.thumbnail = {};
		this.clone = {};
		
		this.create();
	}

	async create() {
		// Elements
		let imageContainer = document.createElement('div');
		imageContainer.style.transform = 'scale(' + this.values.scale + ')';
		imageContainer.className = 'clone__image';
		imageContainer.append(this.DOM.img)

		let clone = document.createElement('div');
		clone.className = 'clone clone-thumbnail';

		clone.append(imageContainer)

		/* Style */
		let thumbnailTransform = {
			scaleX: this.values.imageWidthEnd / this.values.imageWidthStart,
			scaleY: this.values.imageHeightEnd / this.values.imageHeightStart
		};

		this.clone.scale = 'scale(' + thumbnailTransform.scaleX + ', ' + thumbnailTransform.scaleY + ')'

		/* Image Resize */
		let scale = {
			x: (this.values.imageWidthStart / this.values.imageWidthEnd) / (this.DOM.img.naturalHeight / this.values.imageHeightEnd),
			y: (this.values.imageHeightStart / this.values.imageHeightEnd) / (this.DOM.img.naturalHeight / this.values.imageHeightEnd)
		}

		let scaleFullScreen;

		if (this.values.ratioBrowser > this.values.ratioImage) {
			// Landscape
			scaleFullScreen = {
				x: 1 / (this.DOM.img.naturalWidth / this.values.imageWidthStart),
				y: 1 / (this.DOM.img.naturalWidth / this.values.imageWidthStart),
			}
		} else {
			// Portrait (or perfect ratio lol)
			scaleFullScreen = {
				x: 1 / (this.DOM.img.naturalHeight / this.values.imageHeightStart),
				y: 1 / (this.DOM.img.naturalHeight / this.values.imageHeightStart),
			}
		}

		this.FULLSCREEN = scaleFullScreen;

		this.image.scale = 'scale(' + scale.x + ', ' + scale.y + ')';
		this.image.scaleX = thumbnailTransform.scaleX;
		this.image.scaleY = thumbnailTransform.scaleY;

		let position = {
			x: (((window.innerWidth - this.values.imageWidthEnd) / 2) * (-1)) + this.values.posX,
			y: (((window.innerHeight - this.values.imageHeightEnd) / 2) * (-1)) + this.values.posY
		}

		this.clone.translate = 'translate3d(' + position.x + 'px, ' + position.y + 'px, 0)';
		clone.style.transform = this.clone.translate + ' ' + this.clone.scale;

		clone.style.setProperty('--scale-x', this.image.scaleX);
		clone.style.setProperty('--scale-y', this.image.scaleY);

		clone.querySelector('img').style.transform = this.image.scale;

		if (this.location === 'modal') {
			clone.classList.add('clone--modal')
			clone.style.zIndex = 20;
			this.DOM.el.closest('[data-transition-keep]').style.visibility = 'hidden';

		} else {
			this.DOM.el.style.visibility = 'hidden';
		}
		
		document.body.append(clone);
		
		this.DOM.clone = clone;
		
		setTimeout(() => {
			this.animate();
		}, 250)
	}

	resize() {
		let clone = this.DOM.clone;

		/* Container Resize */
		let thumbnailTransform = {
			scaleX: this.values.imageWidthEnd / this.values.imageWidthStart,
			scaleY: this.values.imageHeightEnd / this.values.imageHeightStart
		};

		this.clone.scale = 'scale(' + thumbnailTransform.scaleX + ', ' + thumbnailTransform.scaleY + ')'

		/* Image Resize */
		let scale = {
			x: (this.values.imageWidthStart / this.values.imageWidthEnd) / (this.DOM.img.naturalHeight / this.values.imageHeightEnd),
			y: (this.values.imageHeightStart / this.values.imageHeightEnd) / (this.DOM.img.naturalHeight / this.values.imageHeightEnd)
		}

		let scaleFullScreen;

		if (this.values.ratioBrowser > this.values.ratioImage) {
			// Landscape
			scaleFullScreen = {
				x: 1 / (this.DOM.img.naturalWidth / this.values.imageWidthStart),
				y: 1 / (this.DOM.img.naturalWidth / this.values.imageWidthStart),
			}
		} else {
			// Portrait (or perfect ratio lol)
			scaleFullScreen = {
				x: 1 / (this.DOM.img.naturalHeight / this.values.imageHeightStart),
				y: 1 / (this.DOM.img.naturalHeight / this.values.imageHeightStart),
			}
		}

		this.FULLSCREEN = scaleFullScreen;

		this.image.scale = 'scale(' + scale.x + ', ' + scale.y + ')';
		this.image.scaleY = thumbnailTransform.scaleY;

		this.position()
	}

	position() {
		let clone = this.DOM.clone;

		let position = {
			x: (((window.innerWidth - this.values.imageWidthEnd) / 2) * (-1)) + this.values.posX,
			y: (((window.innerHeight - this.values.imageHeightEnd) / 2) * (-1)) + this.values.posY
		}

		this.clone.translate = 'translate3d(' + position.x + 'px, ' + position.y + 'px, 0)';
		clone.style.transform = this.clone.translate + ' ' + this.clone.scale;

		clone.style.setProperty('--scale', this.image.scaleY);

		clone.querySelector('img').style.transform = this.image.scale;

		if (this.location === 'modal') {
			clone.style.zIndex = 20;
			this.DOM.el.closest('[data-transition-keep]').style.visibility = 'hidden';

		} else {
			this.DOM.el.style.visibility = 'hidden';

		}

		document.body.append(clone);
		this.DOM.clone = clone;
	}

	animate() {
		this.DOM.clone.querySelector('.clone__image').style.transform = null;
		this.DOM.clone.style.transform = 'scale(' + this.image.scaleY + ', ' + this.image.scaleY + ')';

		this.DOM.img.style.transform = 'scale(' + this.FULLSCREEN.x + ', ' + this.FULLSCREEN.y + ')';
	}

	enlargen() {
		this.DOM.clone.setAttribute('data-enlargen', '');
		this.DOM.clone.style.transform = null;

		setTimeout(() => {
			document.querySelector('[data-transition="target"].background-image').style.visibility = 'visible';
			
		}, 1000)


		setTimeout(() => {
			this.delete();
		}, 2000)
	}

	delete() {
		this.DOM.clone.remove()
	}
}

class IntroClone {
	constructor(el) {
		this.DOM = {
			el: el
		};

		this.clone = el.cloneNode(true)

		this.position = {
			x: el.getBoundingClientRect().left,
			y: el.getBoundingClientRect().top
		}
	}

	create() {
		this.clone.classList.add('clone', 'clone-intro')
		this.clone.style.top = this.position.y + 'px';
		this.clone.style.left = this.position.x + 'px';
	}

	append() {
		document.body.append(this.clone);
		this.DOM.el.style.visibility = 'hidden'
		setTimeout(() => {
			this.clone.dataset.transitionKeep = 'keep'
		})
	}

	move(target) {
		let position = {
			x: target.querySelector('div').getBoundingClientRect().left - this.clone.querySelector('div').getBoundingClientRect().left,
			y: target.querySelector('div').getBoundingClientRect().top - this.clone.querySelector('div').getBoundingClientRect().top
		}

		this.clone.style.transform = 'translate3d(0, ' + position.y + 'px, 0)'
	}

	remove() {
		this.clone.remove();
	}
}

const transitionImage = async (element) => {
	ready = false;

	setTimeout(() => {
		window['hasTransitioned'] = true;
	})

	let clone = element.cloneNode(true);
	clone.classList.add('clone', 'clone-member')
	clone.style.position = 'fixed';

    let scale = element.querySelector('img').getBoundingClientRect().width / element.querySelector('img').offsetWidth;

    clone.querySelector('.image-content').style.transform = 'scale(' + scale + ')';

	clone.style.left = element.getBoundingClientRect().left + 'px';
	clone.style.top = element.getBoundingClientRect().top + 'px';
	clone.style.width = element.clientWidth + 'px';
	document.body.appendChild(clone);

	element.style.visibility = 'hidden';

	// window['hasTransitioned'] = true;
	await waitFor(_ => !window['isAnimating']);
	await waitFor(_ => document.querySelector('.entries > *:first-child'));

	let target = document.querySelector('.entries > *:first-child .image-container');

	target.style.visibility = 'hidden';
	ready = true;

	target.addEventListener('transitionend', function removeClone(e) {
		if (e.propertyName === "clip-path") {
			target.removeEventListener('transitionend', removeClone)
			setTimeout(() => {
				// return
				target.style.visibility = null;
				clone.remove();
			}, 250)
		}
	})

	let targetLeft = target.getBoundingClientRect().left;
	let targetTop = target.getBoundingClientRect().top;

	let differenceX = targetLeft - clone.getBoundingClientRect().left;
	let differenceY = targetTop - clone.getBoundingClientRect().top;


	clone.style.transform = 'translate3d(' + differenceX + 'px, ' + differenceY + 'px, 0)';
	clone.style.width = target.clientWidth + 'px';

	clone.querySelector('img').style.transform = 'scale(1)'
}

const transitionHeading = async (element) => {
	window['hasTransitioned'] = false;

	let clone = element.cloneNode(true);
	clone.style.width = element.clientWidth + 'px';
	clone.style.height = element.clientHeight + 'px';
	clone.style.position = 'fixed';
	clone.style.left = element.getBoundingClientRect().left + 'px';
	clone.style.top = element.getBoundingClientRect().top + 'px';

	// remove Transition and second line
	clone.querySelector('.mask').style.clipPath = 'unset';
	clone.querySelectorAll('.mask')[1].remove();
	document.body.appendChild(clone);

	await waitFor(_ => document.querySelector('.active h2.title'));
	let target = document.querySelector('h2.title .mask:first-child');

	target.style.visibility = 'hidden';
	
	clone.addEventListener('transitionend', function removeElement() {
		clone.removeEventListener('transitionend', removeElement)
		window['hasTransitioned'] = true;
	})
	
	target.addEventListener('transitionend', function removeClone(e) {
		if (e.propertyName == "clip-path") {
			target.removeEventListener('transitionend', removeClone)
			setTimeout(() => {
				target.style.visibility = null;
				clone.remove();
			})
		}
	})

	let targetLeft = target.getBoundingClientRect().left;
	let targetTop = target.getBoundingClientRect().top;

	let differenceY = targetTop - clone.getBoundingClientRect().top;

	clone.style.transition = 'transform var(--transition-duration-m) var(--transition-curve-x)';
	clone.style.transform = 'translate3d(0, ' + differenceY + 'px, 0)';
}

class memberClone {
	constructor(el) {
		this.DOM = {
			el: el
		};

		this.clone = el.cloneNode(true)

		this.position = {
			x: el.getBoundingClientRect().left,
			y: el.getBoundingClientRect().top
		}
	}

	create() {
		// set attributes
		this.clone.classList.add('clone', 'clone-member')

		// set styles
		clone.style.left = element.getBoundingClientRect().left + 'px';
		clone.style.top = element.getBoundingClientRect().top + 'px';
		clone.style.width = element.clientWidth + 'px';
		document.body.appendChild(clone);

	}
}